<template>
  <TosListVuetify :entityName="entityName" :headers="headers">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-text-field :label="tLabel('codice')" v-model="slotProps.data.codice"  />
          </v-col>          
          <v-col cols="6">
            <v-select :label="tLabel('tipo_uti')" v-model.number="slotProps.data.tipoUtiId" :items="tipiUtiWithAll" item-text="label" item-value="id"/>            
          </v-col>          
        </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
            <v-col cols="4">
              <v-text-field :label="tLabel('codice')+'*'" v-model="slotProps.data.codice" :rules="[rules.required]" :error-messages="slotProps.errors.codice" />
            </v-col>          
            <v-col cols="4">
              <v-select :label="tLabel('tipo_uti')+'*'" v-model.number="slotProps.data.tipoUtiId" :items="tipiUti" item-text="label" item-value="id" :rules="[rules.required]" :error-messages="slotProps.errors.tipoUti"/>            
            </v-col>             
        </v-row>
        <v-row>
            <v-col cols="4">
              <v-text-field :label="tLabel('peso')" v-model="slotProps.data.peso" :error-messages="slotProps.errors.peso" />
            </v-col>
            <v-col cols="4">
              <v-text-field :label="tLabel('lunghezza')" v-model="slotProps.data.lunghezza" :error-messages="slotProps.errors.lunghezza" />
            </v-col>
            <v-col cols="4">
              <v-text-field :label="tLabel('larghezza')" v-model="slotProps.data.larghezza" :error-messages="slotProps.errors.larghezza" />
            </v-col>              
        </v-row>      
        <v-row>            
            <v-col cols="4">
              <v-text-field :label="tLabel('altezza')" v-model="slotProps.data.altezza" :error-messages="slotProps.errors.altezza" />
            </v-col>   
            <v-col cols="4">
              <v-text-field :label="tLabel('tara')" v-model="slotProps.data.tara" :error-messages="slotProps.errors.tara" />
            </v-col>   
            <v-col cols="2">
              <v-checkbox :label="tLabel('pericoloso')" v-model="slotProps.data.pericoloso" :error-messages="slotProps.errors.pericoloso" />
            </v-col>   
            <v-col cols="2">
              <v-checkbox :label="tLabel('reefer')" v-model="slotProps.data.reefer" :error-messages="slotProps.errors.reefer" />
            </v-col>                       
        </v-row>       
      </div>
    </template>

  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "Uti",
  data() {
    return {
      entityName: "uti",
      tipiUti: null,
      tipiUtiWithAll: null,
      headers: [
         { text: "", value: "action" },
         { text: 'Codice',  value: "codice"},
         { text: 'Tipo uti',  value: "tipoUti", link: "true"},
         { text: 'Peso',  value: 'peso'},
         { text: 'Lunghezza',  value: 'lunghezza'},
         { text: 'Larghezza',  value: 'larghezza'},
         { text: 'Altezza',  value: 'altezza'},
         { text: 'Tara',  value: 'tara'},
         { text: 'Pericoloso',  value: 'pericoloso', formatValue:'formatBoolean'},
         { text: 'Reefer',  value: 'reefer', formatValue:'formatBoolean'}

      ],
    };
  },
  components: {
    TosListVuetify: TosListVuetify
  },
  created() {
  },
  async mounted(){
    const tipiUtiResp = await this.$api.get(this.$apiConfiguration.BASE_PATH+"tipiUti/shortList");
    this.tipiUti = tipiUtiResp.data;
    this.tipiUtiWithAll = [ {id: null, label: "Tutti"}, ...this.tipiUti ];
  },
  methods: {
    validate(item) {
       let errors = {};
       // TODO
       return errors;
    }
  },
  mixins: [FormatsMixins]
};
</script>
